@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Alumni+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Alumni Sans', sans-serif;
  background-color: #282828;
  overflow-x: hidden;
}

.swiper-container {
  width: 480px;
}

@media screen and (min-width: 640px) {
  .swiper-container {
    width: 640px;
  }
}

@media screen and (min-width: 768px) {
  .swiper-container {
    width: 768px;
  }
  .map-container {
    width: 100%;
    height: 40vh;
    border-radius: 10px;
  }
}

  @media screen and (min-width: 1024px) {
    .swiper-container {
      width: 1024px;
    }

     .bg-services {
      background-image: url("/public/images/services3.png");
      padding: 0 24% !important;
    }
  
}

.swiper-button-next {
  background-image: url("/public/images/icons/arrow-right.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev {
  background-image: url("/public/images/icons/arrow-left.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-prev::after {
  display: none;
}

.map-container {
  width: 100%;
  height: 70vh;
}

.contact-bg {
  background-position: left 25% bottom 30%;
}


.products-container img {
  border-radius: 0.75rem;
}